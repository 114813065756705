import { Environment } from '@core-hr/shared'

export const environment: Environment = {
  firebase: {
    projectId: 'baylaurel-development-3',
    appId: '1:960097504984:web:0d5198c33cf3aa330191ad',
    storageBucket: 'baylaurel-development-3.appspot.com',
    locationId: 'asia-northeast1',
    apiKey: 'AIzaSyDFwnOCtrXzaxC_aPZewnXt3Icdgwx6qDk',
    authDomain: 'baylaurel-development-3.firebaseapp.com',
    messagingSenderId: '960097504984',
    measurementId: '',
  },
  production: false,
  useEmulators: false,
  env: 'development-3',
  moduleFederationManifestPath: '/assets/module-federation.manifest.dev3.json',
  useChat: false,
  chatCode: '',
}
